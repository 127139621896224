<i18n>
{
  "en": {
    "browser_title": "Compliance with 18 U.S.C. 2257"
  },
  "ja": {
    "browser_title": "Compliance with 18 U.S.C. 2257"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="usc2257" class="page-margin">
      <div class="container">
        <div class="section">
          <div class="wysiwyg">
            <div v-html="jsonHtml[locale]"></div>
          </div>
        </div>
      </div>
    </div><!-- /#usc2257 -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  components: {
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      dynamicTitle: '',
      jsonHtml: {},
    };
  },
  created() {
    this.refreshHTML();
  },
  methods: {
    async refreshHTML() {
      const bfAPI = new BifrostAPI();
      const templates = await bfAPI.getStaticPage();
      this.jsonHtml = templates['2257'];
    },
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_2257.scss';
</style>
